import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "date": "2020-02-08T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <blockquote>
      <p parentName="blockquote">{`Perfect is the enemy of good.`}</p>
    </blockquote>
    <p>{`— `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Voltaire"
      }}>{`Voltaire`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      